.compression {
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.compressionForm {
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.compressionInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.compressionInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 320px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.compressionInput > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #193150;
}

.checkboxInput {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkboxInput > div {
  display: flex;
  gap: 20px;
}

.saveButtons {
  width: 100%;
  display: flex;
  justify-content: end;
}

.saveButtons > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: none;

  width: 81px;
  height: 40px;
  left: 329px;
  top: 0px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;

  background: #72d961;
  border-radius: 8px;
}

.compressionTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #d0d5dd;
  margin-top: 25px;
  padding: 20px;
}

.compressionDate {
  width: 100%;
  border: 1px solid #d0d5dd;
  flex-direction: column;
}

.compressionTitle {
  display: flex;
}

.position {
  border-right: 1px solid #d0d5dd;
  width: 20%;
  display: flex;
  align-items: center;
}

.span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;

  color: #193150;
}

.description {
  border-right: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  width: 57%;
}

.container {
  display: flex;
  border: 1px solid #d0d5dd;
}

.settings {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.edit {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #e8ffe4;
  border-radius: 8px;
}

.edit > img {
  width: 25.34px;
  height: 25.34px;
}

.delete {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffd2d2;
  border-radius: 8px;
}

.delete > img {
  width: 25.34px;
  height: 25.34px;
}

.compressionModal {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d5dd;
  padding: 20px;
  width: 100%;
}

.modalInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modalInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;

  width: 367px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #193150;
}


.inputCheckbox{
  margin-top: 20px;
}

.save{
  display: flex;
  justify-content: end;
  gap: 20px;
}


.saveButton{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px;
gap: 10px;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
width: 81px;
height: 40px;
background: #72D961;
border-radius: 8px;
border:none;
}


.cancelButton{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 16px;
gap: 8px;
font-weight: 600;
font-size: 14px;
line-height: 20px;





color: #344054;
width: 81px;
height: 40px;



background: #FFFFFF;


border: 1px solid #D6BBFB;


box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
}