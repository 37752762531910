
.settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 32px 48px;
  gap: 32px;
  margin-top: 12px;

  width: 53%;
  height: 936px;

  background: #ffffff;
  border-radius: 40px 0px 0px 40px;
}


.settingsTitle{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

}

.settingsTitle>span{
font-weight: 500;
font-size: 30px;
line-height: 38px;
color: #193150;

}


.settingsTitle>button{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 16px;
gap: 8px;
width: 127px;
height: 40px;
background: #72D961;
border: 1px solid #72D961;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;


}

.settingsPagination{
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 47px;
}

.linkHref{
  text-decoration: none;
  font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #193150;

}