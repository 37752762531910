.createNewPassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.createPassText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 80px;
}

.createPassText > span {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #193150;
}

.createPassText > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #193150;
}

.createForm {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.passInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.passInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 320px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.passInput > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}
.newPassIcon {
  width: 20px;
  height: 17px;
  position: absolute;
  right: 20px;
  top: 66%;
  transform: translateY(-50%);

}

.confirmPassIcon {
  width: 20px;
  height: 17px;
  position: absolute;
  right: 20px;
  top: 66%;
  transform: translateY(-50%);
}

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
}

.loginButton > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;

  width: 246px;
  height: 40px;

  background: #1c7df8;
  border: 1px solid #1c7df8;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
