.verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emailHasBeen {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.emailHasBeen > span {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #193150;
}

.emailHasBeen > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #193150;
}

.emailVerify {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.emailVerify > img {
  width: 200px;
}

.emailVerify > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 246px;
  height: 40px;
  background: #1c7df8;
  border: 1px solid #1c7df8;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.emailVerify > button > a {
  text-decoration: none;
}

.resend {
  border: none;
  background-color: white;
    cursor: pointer;
  color: #1C7DF8;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
