.reparoMenu {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex-direction: row;
  align-items: flex-start;
  background: #ffffff;
}

.reparoTitle {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reparoTitle > button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 231px;
  height: 44px;

  background: #72d961;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.webLink {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.webPageination {
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 8px 12px;
 

  width: 247px;
  height: 50px;

  background: #dfedff;
  border-radius: 6px;
}


.webPageination>a{
  text-decoration: none;
  font-weight: 500;
font-size: 16px;
line-height: 24px;



color: #193150;

}

.reparoTitle>img{
  width: 100px;
  height: 50px;
}

.exit{
  margin-top: 50px;
  border-top:1px solid #193150; ;
}

.buttonExit{
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}