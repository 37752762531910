.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  margin-top: 60px;
  width: 20%;
}

.loginText > span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;

  color: #193150;
}

.loginText > p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #193150;
}

.loginForm {
  display: flex;
  flex-direction: column;
  margin-top: 84px;

  gap: 8px;
}

.loginInput {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.loginInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.loginInput > input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.viewIcon {
  width: 20px;
  position: absolute;
  right: 20px;
  top: 66%;
  transform: translateY(-50%);
 
}

.forgot {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.forgot > a {
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1c7df8;
}

.forgot > div {
  display: flex;
  gap: 10px;
}

.loginButton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
}

.loginButton > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 246px;
  height: 40px;
  background: #1c7df8;
  border: 1px solid #1c7df8;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.signupHref {
  text-decoration: none;

  width: 295px;
  height: 20px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #1c7df8;
}
