.checkmail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkEmailText {
 
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.checkEmailText > span {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px; 
  color: #193150;
}

.checkEmailText > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #193150;
}


.checkEmailIcon{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.checkEmailIcon>img{
    width: 200px;
}

.inputsCode{
    display: flex;
    gap: 5px;
}

.enter{
    margin-top: 20px;
}

.inputsCode>input{
display: flex;
flex-direction: row;
align-items: center;
padding: 10px 14px;
gap: 8px;
width: 72px;
height: 44px;
background: #FFFFFF;
border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;

}

.continueButton{
    display: flex;
    margin-top: 20px;
}

.continueButton>button{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 16px;
gap: 8px;
width: 246px;
height: 40px;
background: #1C7DF8;
border: 1px solid #1C7DF8;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
}