.caching {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.cachingContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 20px;

  width: 100%;

  background: #ffffff;

  border: 1px solid #d0d0d0;
  box-shadow: 1px 3px #e0ffff;
}

.cachingSelect {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.enter {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.enter > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: black;
}

.enter > select {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.switchButton {
  display: flex;
  align-items: center;
  gap: 20px;
}

.purge {
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 140px;
  height: 40px;
  background: #ff2323;
  border: 1px solid #ff2323;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.purgeCreateButtons {
  display: flex;

  justify-content: space-between;
}

.rule {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 148px;
  height: 40px;
  background: #72d961;
  border: 1px solid #72d961;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.url {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 320px;
  height: 44px;

  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.url::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}
