.signUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  width: 100%;
}

.signUpText {
  margin-top: 100px;
}

.signUpText > span {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #193150;
}

.signUpForm {
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formInput {
  display: flex;
  flex-direction: column;
}

.formInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.formInput > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.formInputIcon {
  display: flex;
  flex-direction: column;
  position: relative;
}

.formInputIcon > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.formInputIcon > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #667085;
}

.viewIcon {
  position: absolute;
  width: 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.confirmViewIcon {
  position: absolute;
  top: 200px;
  width: 20px;
  top: 559px;
  right: 818px;
}

.signUpButton>button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 246px;
  height: 40px;

  background: #1c7df8;
  border: 1px solid #1c7df8;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}




.signUpButton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    gap: 16px;
}

.loginHref{
  text-decoration: none;
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */


color: #1C7DF8;
}