.cachingRule {
  margin-top: 30px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  padding: 8px 12px;

  width: 100%;

  background: #ffffff;
  border: 1px solid #d0d5dd;
}

.cachingRuleHeader {
  display: flex;
  
}

.position {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 105px;

  width: 258px;
  height: 40px;

  background: #ffffff;
  border: 1px solid #d0d5dd;
}

.urlDescription {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 105px;

  width: 566px;
  height: 40px;

  background: #ffffff;
  border: 1px solid #d0d5dd;
}

.action {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
 

  width: 233px;
  height: 40px;

  background: #ffffff;
  border: 1px solid #d0d5dd;
}


.cachingRuleTables{
  display: flex;
  flex-direction: column;
  
}


.ruleTable{
  display: flex;
  

  align-items: center;
}

.priority{
  display: flex;
flex-direction: row;
align-items: center;
padding: 8px 12px;


width: 253px;
height: 48px;

background: #FFFFFF;

}

.hour{
  width: 566px;
height: 48px;

background: #FFFFFF;

}


.actionContainer{
  display: flex;
  gap: 26px;
  
  align-items: center;
}



.edit{
  width: 32px;
height: 32px;
display: flex;
align-items: center;
justify-content: center;
background: #E8FFE4;
border-radius: 8px;
}

.delete{
  width: 32px;
height: 32px;

background: #FFD2D2;
border-radius: 8px;
}

.actionContainer>div{
  display: flex;
}

.edit>img{
  width:25.34px;

height:25.34px

}