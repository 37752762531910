.forgot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forgotText {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.forgotText > span {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #193150;
}

.forgotText > p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #193150;
}

.forgotText > img {
  width: 117px;
  height: 82px;
  margin-top: 30px;
}

.forgotForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.forgotInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.forgotInput > input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.forgotForm > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 246px;
  height: 40px;

  background: #1c7df8;
  border: 1px solid #1c7df8;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
