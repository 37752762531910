.pageImage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  width: 100%;
  height: 300px;
  border: 1px solid #d0d5dd;

  background: white;
  border-radius: 0px;
}

.inputUrl {
  display: flex;

  margin-top: 10px;

  width: 578px;
  height: 128px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.inputUrl::placeholder {
  position: absolute;
  top: 10%;
  left: 2%;
}








.convert {
  width: 162px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #1c7df8;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #1c7df8;
}

.converContainer {
  margin-top: 10px;
}

.save {
  display: flex;
  justify-content: end;
  width: 157%;
}

.save > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 126px;
  height: 40px;

  background: #72d961;
  border: 1px solid #72d961;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.imageTable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  height: auto;

  width: 100%;

  border: 1px solid #d0d5dd;

  background: white;
  border-radius: 0px;
}

.pageImageTable {
  display: flex;
  height: 284px;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll
}

.exportButtons {
  display: flex;

  width: 90%;
  justify-content: end;
  gap: 20px;
}

.export {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid #1c7df8;
  width: 162px;
  height: 40px;
  color: #1c7df8;
  background: #ffffff;
}

.delete {
  width: 138px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid #f81c1c;
  border-radius: 8px;
  color: #f81c1c;
}


.informationImage{
   width: 90%;
   border:1px solid #D0D5DD ;
   margin-top: 42px;
   display: flex;

}

.checkboxHeader{
 
 
    width: 37px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right:1px solid #D0D5DD
}


.name{
    display: flex;
    border-right:1px solid #D0D5DD;
    width: 79%;
    
    align-items: center;
}

.name>span{
  margin-left: 10px;
}

.span{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
   
    color: #193150;
    
}

.uploadDate{
display: flex;
align-items: center;
width: 41.3%;
}

.uploadDate>span{
  margin-left: 10px;
}

.getData{
  border:1px solid #D0D5DD;
 width: 90%;
  display: flex;
}

.containerFilename{
  display: flex;
 
}


.dateImage{
  
  font-size: 10px;
}


.filename{
  display: flex;
  border-right:1px solid #D0D5DD;
  width: 63%;

  align-items: center;
  
}

.filename>span{
  margin-left: 10px;
}
.createdDate{
  display: flex;
  align-items: center;
  justify-content: center;
}
.createdDate>span{
  
margin-left: 10px;
 
 
}


.checkboxTable{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right:1px solid #D0D5DD
}