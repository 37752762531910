.editRule {
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: column;
  width: 80%;
  
  padding: 20px;
}

.containerEdit {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 20px;
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  width: 320px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}


.span{
    font-weight: 500;
font-size: 14px;
line-height: 20px;



color: #193150;

}

.chachingButtons{
  display: flex;
  
  width:100%;
  justify-content: end;
  gap: 20px;
}

.save{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px;
gap: 10px;

border:none;
width: 81px;
height: 40px;


font-weight: 500;
font-size: 14px;
line-height: 20px;



color: #FFFFFF;

background: #72D961;
border-radius: 8px;
}


.cancel{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px;
gap: 10px;
border:none;
  width: 81px;
height: 40px;
  background-color: #DFEDFF;
  color: #193150;
  border-radius: 8px;
  font-weight: 500;
font-size: 14px;
line-height: 20px;

}